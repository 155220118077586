html, body
{
    height: 100%;
}

.dashboard-sidecard {
    min-height: 100vh;
}

.rows{
    display: flex;
    flex-direction: column;
}

.center{
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    /* text-align: center;
    display: block; */
    /* justify-content: center; */
    /* align-items: center; */
    /* margin: auto;
    width: 50%; */
  }